import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-mini';
import { truncateString, getUtilityDescription, getUtilityIcon } from '../../managers/Helpers';

function DigitalAssetTile({ asset, assetType }) {
  const [utility, setUtility] = useState([]);

  const combineUtility = (utility) => {
    console.log('=== COMBINE UTILITY ===');
    const combinedUtility = utility.collectionUtility.concat(utility.nftUtility);
    setUtility(combinedUtility.slice(0, 2));
  };

  useEffect(() => {
    if (asset.utility) {
      combineUtility(asset.utility);
    }
  }, []);

  return (
    <div className="digital-assets__tile">
      <div className="digital-assets__tile--flip">
        <div className="digital-assets__tile--inner">
          <div className="digital-assets__tile--image-front">
            <div className="digital-assets__tile--image-holder">
              <Link to={'/asset/' + asset.nftId}>
                {assetType === 'video'
                    && (
                    <video autoPlay="autoplay" loop muted>
                      <source src={asset.imageUrl || asset.asset.mediaMP4} type="video/mp4" />
                      <track
                    default
                    kind="captions"
                    srcLang="en"
                    src="" />
                      Your browser does not support the video tag.
                    </video>
                    )}
                {assetType === 'image'
                    && <img src={asset.imageUrl} alt="Digital asset thumbnail" />}
                {assetType === 'pdf'
                    && <img src={asset.asset.mediaLocalIPFS} alt="Digital asset thumbnail" />}
              </Link>
            </div>
          </div>
          <div className="digital-assets__tile--image-back">
            <div className="digital-assets__tile--benefits">
              <div className="digital-assets__tile--benefits-title">Benefits</div>
              { utility.length > 0 ? (
                <ul className="digital-assets__tile--benefits-list">
                  {utility.map((item) => (
                    <li key={item.utilityId}>
                      <div className="benefit-icon">
                        <img src={getUtilityIcon(item.utilityType)} alt="benefit icon" />
                      </div>
                      <div className="benefit-title">
                        {item.utilityDescription}
                      </div>
                      <div className="benefit-description" dangerouslySetInnerHTML={{ __html: getUtilityDescription(item) }}></div>
                    </li>
                  ))}
                </ul>
              )
                : (
                  <div className="digital-assets__tile--no-benifits">
                    {asset.asset.assetDescription
                    && <div>{truncateString(asset.asset.assetDescription, 250) + '...'}</div>}
                  </div>
                )}
              <div className="digital-assets__tile--benefits-cta">
                <Link className="button-benefits-tile" to={'/asset/' + asset.nftId}>
                  View more details
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="digital-assets__tile--details">
        {asset.assetVariation
          ? (
            <div>
              <div className="digital-assets__tile--title">
                {asset.assetVariation.variationName}
              </div>
              <div className="digital-assets__tile--date">
                Joined
                {' '}
                {moment(asset.txn.dateStamp).format('MMMM DD, YYYY')}
              </div>
            </div>
          )
          : (
            <div>
              <div className="digital-assets__tile--title">
                {asset.asset.name}
              </div>
              <div className="digital-assets__tile--date">
                Joined
                {' '}
                {moment(asset.txn.dateStamp).format('MMMM DD, YYYY')}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default DigitalAssetTile;
