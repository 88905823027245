import React, { Component } from 'react';
// import EDropLanding from '../components/transfer/edropLanding';
import EDropLanding from '../components/edrop/edropLanding';
import WickedHowItWorks from '../components/edrop/WickedHowItWorks';
import PreviewBenefits from '../components/edrop/previewBenefits';
import EDropFlexContent from '../components/edrop/edropFlexContent';
import EDropFAQ from '../components/edrop/edropFAQ';
import AssetFAQ from '../components/collection/assetFAQ';
import { getParameterByName } from '../managers/Helpers';
import AccountManager from '../managers/Account';
import TransferChecker from '../components/transfer/transferChecker';
import SimpleModal from '../components/modal/modal';
import Throbber from '../components/throbber';
import UtilityManager from '../managers/Utility';

class EDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
      transferToken: null,
      transferCookie: null,
      asset: null,
      showModal: true,
      benefits: [],
    };
  }

  setTransferToken = (token) => {
    Object.entries(sessionStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('NFT_TRANSFER')) {
        sessionStorage.removeItem(key);
      }
      return true;
    });
    // force the one in the URL to be in memory
    this.setState({
      transferCookie: 'NFT_TRANSFER_' + token,
      transferToken: token,
    });
    // sessionStorage.setItem('NFT_TRANSFER_' + token, token);
  }

  componentDidMount() {
    document.title = process.env.CREATOR_SUBDOMAIN === 'account' ? 'ethos' : process.env.SITE_NAME + ' | Join the ' + process.env.DIGITAL_ASSET_TYPE;
    if (getParameterByName('id', window.location.href)) {
      this.setTransferToken(getParameterByName('id', window.location.href));
    } else if (process.env.EDROP_CLAIM_TOKEN) {
      this.setTransferToken(process.env.EDROP_CLAIM_TOKEN);
    } else {
      window.location.href = '/';
    }
  }

  loadTransferDetails = async (transferDetails) => {
    console.log('=== WE HAVE TRASNFER DETAILS ===');
    console.log(transferDetails);
    if (transferDetails.asset) {
      this.setState({
        asset: transferDetails.asset,
        showModal: false,
      });
    }
    // get any preview benefits
    const previewBenefits = await UtilityManager.getPreviewUtility(transferDetails.asset.assetId);
    if (previewBenefits && previewBenefits.length > 0) {
      this.setState({
        benefits: previewBenefits,
      });
    }
  }

  componentWillUnmount() {
    document.title = process.env.SITE_NAME;
  }

  render() {
    const {
      showModal, transferCookie, transferToken, asset, benefits,
    } = this.state;
    return (
      <div>
        <SimpleModal isOpen={showModal}>
          <Throbber throbberText="Loading claim page..." />
        </SimpleModal>
        {/* {transferToken && AccountManager.isLoggedIn()
          ? (
            <TransferChecker
              isAuthed={AccountManager.isLoggedIn()}
              transferToken={transferToken} />
          )
          : <EDropLanding onTransferDetailsLoaded={(transferDetails) => this.loadTransferDetails(transferDetails)} transferCookie={transferCookie} transferToken={transferToken} />} */}
        
        <EDropLanding onTransferDetailsLoaded={(transferDetails) => this.loadTransferDetails(transferDetails)} transferCookie={transferCookie} transferToken={transferToken} />

        {asset
          && <WickedHowItWorks asset={asset} />}

        {asset && benefits.length > 0
          && <PreviewBenefits asset={asset} benefits={benefits} />}

        {asset
          && <EDropFlexContent asset={asset} />}

        {asset
          && (
          <div className="storefront-faq">
            <div className="storefront-faq--title">FAQ</div>
            <AssetFAQ asset={asset} noHeader />
          </div>
          )}
      </div>
    );
  }
}

export default EDrop;
